import Password from "../components/Password";
import HomeLayout from "../components/[city]/_homeLayout";
import { GetCityName } from "../hook/GetCityName";

import Variables from "../variables/variables.json";

export default function Index(props) {
  const [CityName] = GetCityName();

  let Miasto = CityName;
  let Podatek = "index";

  return (
    <>
      {Variables.miasta[Miasto].password ? (
        <Password>
          <HomeLayout miasto={Miasto} podatek={Podatek}></HomeLayout>
        </Password>
      ) : (
        <HomeLayout miasto={Miasto} podatek={Podatek}></HomeLayout>
      )}
    </>
  );
}
